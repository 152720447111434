// import React, {Component} from "react";
import './Carousel.css'

import React, { useEffect, useState } from 'react'

function Carousel({images}) {
    const [current, setCurrent] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    let timeOut = null;

    useEffect(()=> {
        timeOut = autoPlay &&
        setTimeout(() => {
            slifeRight();
        }, 2500);
    });

    
    const slifeRight = () => {
        setCurrent(current === images.length -1 ? 0 : current + 1);
        // if (current === images.length -1){
        //     setCurrent(0);
        // }else {
        //     setCurrent(current + 1);
        // }        
    }

    const slifeLeft = () => {
        setCurrent(current === 0 ? images.length -1 : current - 1);
        // setCurrent(current - 1);
    }

  return (
    <div className='carousel'
    onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timeOut);
    }}
    onMouseLeave={() => {
        setAutoPlay(true);
    }} 
    >
        <div className='carousel_wrapper'>
            {images.map((image, index) => {
                return (
                    <div key={index} className={index===current ? "carousel_card carousel_card-active" : "carousel_card"}>
                        <img className='card_image' src={image.image} alt="" />
                        <div className='card_overlay'>
                            {/* <h2 className='card_title'> Welcome to TrinityX</h2> */}
                            <h2 className='card_title'> Real Estate, End to End</h2>
                        </div>
                    </div>
                );
            })}
            <div className='carousel_arrow_left' onClick={slifeLeft}>&lsaquo;</div>
            <div className='carousel_arrow_right' onClick={slifeRight}>&rsaquo;</div>
            <div className='carousel_pagination'>
                {images.map((_,index)=> {
                    return (
                        <div key={index} className={ index === current ? "pagination_dot pagination_dot-active" : "pagination_dot"} onClick={() => setCurrent(index)}>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default Carousel
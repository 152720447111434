export const MenuItems = [
    // {
    //     title: 'Home',
    //     url: '#',
    //     cName: 'nav-links'
    // },
    {
        title: 'About-Us',
        url: '#About-Us',
        cName: 'nav-links'
    },
    {
        title: 'Contact-Us',
        url: '#Contact-Us',
        cName: 'nav-links'
    },
    // {
    //     title: 'Services',
    //     url: '#',
    //     cName: 'nav-links'
    // },
    // {
    //     title: 'Sign up',
    //     url: '#',
    //     cName: 'nav-links-mobile'
    // },
]
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItems } from "./MenuItems";
import logo from "./TrinityX Logo.png"
import './Navbar.css'


function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (item) => {
    // Check if the item has a local reference (id)
    if (item) {
      // Get the corresponding element by id
      const targetElement = document.getElementById(item);

      // Scroll to the target element
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

    // Close the menu
    handleCloseNavMenu();
  };

  return (
    <AppBar  position="static" sx={{backgroundColor: "black", display: 'block'}}>
        <Container >
            <Toolbar >
                {/* Desktop */}
                
                <Box
                component="img"
                alt="Logo"
                src={logo}
                sx={{display:{xs:'none', md: 'flex', height: 38}, mr: 1}}
                />
                <Typography
                variant='h6'
                // noWrap
                component="a"
                sx={{
                    flexGrow: 16,
                    display: {xs:"none", md: 'flex'},
                    fontFamily: 'Gotham-Bold',
                    
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: '22px'
                }}
                >
                TRINITY X
                </Typography>
                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}> 
                    { MenuItems.map((item, index)=>{
                        return (
                            <Button
                            key={index}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 1, color: 'white', display: 'block' }}
                            >
                            {item.title}
                            </Button>
                        )})
                    }
                    <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white' }}
                    endIcon={<KeyboardArrowDownIcon />}
                    >Services
                    </Button>
                </Box>



                    {/* MOBILE */}
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >   
                    <MenuItem onClick={() => handleMenuItemClick('services')}>
                    Services
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('About-Us')}>
                    About us
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Contact-Us')}>
                    Contact us
                    </MenuItem>

                    
                </Menu>

                <Grid container direction="row" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Grid item >
                        <img src={logo} alt="" style={{ maxWidth: '100%', maxHeight: '100%', maxHeight: '40px' }} />
                    </Grid>
                    <Grid item sx={{ flexGrow: 1, textAlign: 'start', fontSize: '10px'}}>
                        <h1>TRINITY X</h1>
                    </Grid>
                    <Grid item>
                        <IconButton
                            size="large"
                            onClick={handleOpenNavMenu}
                            color='inherit'
                        >
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                </Grid>                        
            </Toolbar>
        </Container>
    </AppBar>
    );
}
export default Navbar;
export const carousel_images = [
    {
        image: "/images/carousel 1.jpg",
        title: "build1",
    },
    {
        image: "/images/carousel 2.jpg",
        title: "build2",
    },
    {
        image: "/images/carousel 3.jpg",
        title: "build3",
    },
    {
        image: "/images/carousel 4.jpg",
        title: "build3",
    },
];
import React from 'react'
import './Footer.css'
import { ServiceItems } from "../Navbar/ServiceItems";
import { Container, Grid, Typography,} from '@mui/material';

function Footer2() {
  return (
    <Container className='footer' disableGutters maxWidth={false}> 
        
        <hr className='hr-opacity'></hr>
        <Grid container spacing={2} direction='row' sx={{ width:'98%',margin:'auto',display: { xs: 'block', md: 'flex' } }}>
            <Grid item >
                <Grid item container direction='column'> 
                {/* <Typography variant='h4' className='sb__footer-links-div'>Our Services</Typography> */}
                <div className='sb__footer-links-div'>Our Services</div>
                <Grid item>
                {ServiceItems.map((item, index)=>{
                                return(
                                    <span key={index}>
                                        <a className='footer-a' href={item.url}>{item.title}</a>
                                    </span>
                                )})   
                            }
                </Grid>
                </Grid>
                
            </Grid>
            <Grid item >
                <Grid item container direction='column'>
                {/* <Typography variant='h4' className='sb__footer-links-div'>More</Typography> */}
                <div className='sb__footer-links-div'>More</div>
                    <Grid item>
                    <a className='footer-a' href='#About-Us'>
                        About us
                    </a>
                    <a className='footer-a' href='#Contact-Us'>
                        Contact us
                    </a>
                    <a className='footer-a' href='#TermsandConditions'>
                        Terms & Conditions
                    </a>
                </Grid>
            </Grid>
            </Grid>

        </Grid>
        <hr className='hr-opacity2'></hr>
    </Container>
  )
}

export default Footer2
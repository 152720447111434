
import { Container, Grid } from '@mui/material'
import React from 'react'
import './AboutUsCarousel.css'
import { createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1536,
    },
  },
});

function Aboutus() {
  return (
    
    <Container id='About-Us' className='aboutus-car' sx={{padding: '0'}} disableGutters maxWidth={false}> 
      <img src='/images/carousel.jpg' alt='AboutUS'  className='carousel-img'></img>
      <div >
      <Grid container direction='row' spacing={0} className='content-car' sx={{width:'85%',display: { xs: 'flex', md: 'none', lg:'none' }}}>
        <Grid item sx={{mt:'0px'}}>
          <h2 >ABOUT US</h2>
        </Grid>
        <Grid item  className='content-car2'>
          <h4>25 Years</h4>
          Combined real-estate experience
          <h6> </h6>
          <h4>IIM GRADUATES</h4>
          <h4>EX-BANKERS</h4>
          <h4>CUSTOMER CENTRIC</h4>
        </Grid>
      </Grid>
      <Grid container direction='row' spacing={0} className='content-car' sx={{width:'85%',display: { xs: 'none', lg: 'flex' }}}>
        <Grid item sx={{mt:'0px', mr: '420px'}} className='content-aboutus' >
          <h2 >ABOUT US</h2> 
        </Grid>
        <Grid item direction='column' spacing={0}>
        <Grid item>
        <h4> </h4>
          500 Cr Investments Facilitated
        <h6> </h6>
          <h4>IIM GRADUATES</h4>
          <h4>EX-BANKERS</h4>
          <h4>CUSTOMER CENTRIC</h4>
        </Grid>
        </Grid>
      </Grid>
      </div>
    </Container>
    
  )
}

export default Aboutus

import { Container, Select, MenuItem, Grid, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
// import TextField from '@mui/material/TextField';
import './ContactUs.css';

function ContactUs2() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSelectChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  const [formData, setFormData] = useState({
    named: '',
    
    phoneNumber: '',
    email: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    submitFormData();
  };

  const submitFormData = async () => {
    const postData = {
      ...formData,
      selectedOptions: selectedOptions,
    };
    const msgbody = {
      Name: formData.named,
      PhoneNumber: formData.phoneNumber,
      SelectedOptions: selectedOptions.join(', '),
    };
    const postData2 = {
      "recipient": formData.email,
      "msgBody":JSON.stringify(msgbody),
      "subject":"New User request mail"
    
    }

    try {
      // Make a POST request to the API endpoint
      const response = await fetch('http://localhost:8080/sendMail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData2),
      });

      if (response.ok) {
        // The request was successful, handle the response
        const data = await response.text();
        console.log('API response:', data);
        setFormSubmitted(true);
      } else {
        // Handle the error response
        console.error('API error:', response.status);
        // You might want to handle the error more gracefully, e.g., show an error message to the user
      }
    } catch (error) {
      console.error('API error:', error);
      // Handle other errors, e.g., network issues
    }
    // Your form submission logic here
    console.log('Form data submitted:', postData);
    setFormSubmitted(true);
  }



  const service_items = [
    { id: 'Office Spaces', name: 'Office Spaces' },
    { id: 'Luxury Homes', name: 'Luxury Homes' },
    { id: 'Land Services', name: 'Land Services' },
    { id: 'Investment', name: 'Investment' },
    { id: 'Funding', name: 'Funding' },
    { id: 'Home Loans', name: 'Home Loans' },
  ];

  return (
    <Container className='contact-container' id='Contact-Us' disableGutters maxWidth={false}>
      <Grid container direction="column" spacing={1} sx={{ width:'95%',margin:'auto',display: { xs: 'flex', md: 'none' } }}>
        <Grid item className='contact-header' style={{ marginTop: '20px' }}>
          <Typography variant="h4" >ContactUs</Typography>
          {/* <h4>Contact Us</h4> */}
        </Grid>
        <Grid item className='contact-data'>
          <Typography variant="body1">
            5th floor, Western Aqua <br />
            WhiteFields,<br />
            HITECH City,<br />
            Hyderabad - 500081
          </Typography>
          <Typography variant="body1">
            <a href='tel:+918688397007' className='contact-a'>
              +91 8688397007
            </a>
          </Typography>
          <Typography variant="body1">
            <a href='mailto:kpsachary@gmail.com' className='contact-a'>
              Contact@trinityx.com
            </a>
          </Typography>
        </Grid>
        <Grid item>
          <form className='contact-font'>
            <input
              id='name'
              type='text'
              name='named'
              placeholder='Name'
              value={formData.named}
              onChange={handleChange}
              style={{ width: '300px', height: '27px', fontSize: '15px' }}
              
            />
              {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" margin="normal"
              style={{ width: '300px',  backgroundColor: 'white' }}
              style={{ width: '300px', height:27, color: 'grey', backgroundColor:'white', marginTop: '10px',fontSize:'15px' }}
              /> */}
            <br/>
            <Select 
              // className='contactus_form_inside'
              multiple
              value={selectedOptions}
              onChange={handleSelectChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ width: '308px', height:27, color: 'grey', backgroundColor:'white', marginTop: '10px',fontSize:'15px' }}
              // autoWidth
              renderValue={(selected) => (selected.length === 0 ? 'I am interested in' : 'I am interested in')}
              className='contact-font'
            >
              <MenuItem disabled value="">
                I am interested in
              </MenuItem>
              {service_items.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <div className='contactus_form_inside'>
            {/* <Typography variant="h6">Selected Items:</Typography> */}
            {selectedOptions.map((item, index) => (
              <Typography key={index} variant="body1">
                {item}
              </Typography>
            ))}
          </div>
            <input
              type="tel"
              id="phone"
              name="phoneNumber"
              placeholder="Contact Number"
              pattern="[0-9]{5}[0-9]{5}"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              size='10'
              className='contact-font'
              style={{ width: '300px', height: '27px', fontSize: '15px', marginTop: '10px' }}
            />
            <br />
            <input
              type="email"
              id='email'
              name='email'
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
              required
              
              className='contact-font'
              style={{ width: '300px', height: '27px', fontSize: '15px', marginTop: '10px' }}
            />
            <br />
            <Button variant="contained" type="submit" onClick={handleClick} style={{ marginBottom: '25px' , marginTop: '10px', backgroundColor:'red'}}> 
              Submit
            </Button>
          </form>
        </Grid>
        <Grid item>
          <Typography></Typography>
        </Grid>
        {/* <Grid item>
          <div style={{ marginTop: '20px' }}>
            <p>Selected Items:</p>
            {selectedOptions.map((item, index) => (
              <Typography key={index} variant="body1">
                {item}
              </Typography>
            ))}
          </div>
        </Grid> */}
        {formSubmitted &&(
          <Grid item>
            <div style={{color:'white', marginBottom: '20px' }}>
              <Typography variant="h6">Thanks!</Typography>
              <Typography variant="body1">Our executive will call you shortly</Typography>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" spacing={1} sx={{ width:'95%',margin:'auto',display: { xs: 'none', md: 'flex' } }}> 
        <Grid item>
        <Grid item className='contact-header' style={{ marginBottom: '15px', marginRight: '280px', marginTop: '20px' }}>
          <Typography variant="h4" >ContactUs</Typography>
        </Grid>
        <Grid item className='contact-data' style={{ marginBottom: '20px'}}>
          <Typography variant="body1">
            5th floor, Western Aqua <br />
            WhiteFields,<br />
            HITECH City,<br />
            Hyderabad - 500081
          </Typography>
          <Typography variant="body1">
            <a href='tel:+918688397007' className='contact-a'>
              +91 8688397007
            </a>
          </Typography>
          <Typography variant="body1">
            <a href='mailto:kpsachary@gmail.com' className='contact-a'>
              Contact@trinityx.com
            </a>
          </Typography>
        </Grid>
        </Grid>
        <Grid item style={{ marginTop: '80px'}}>
          <form className='contact-font'>
            <input
              id='name'
              type='text'
              name='named'
              placeholder='Name'
              value={formData.named}
              onChange={handleChange}
              style={{ width: '400px', height: '27px', fontSize: '15px' }}
            />
            <br/>
            <Select 
              // className='contactus_form_inside'
              multiple
              value={selectedOptions}
              onChange={handleSelectChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ width: '408px', height:27, color: 'grey', backgroundColor:'white', marginTop: '10px',fontSize:'15px' }}
              // autoWidth
              renderValue={(selected) => (selected.length === 0 ? 'I am interested in' : 'I am interested in')}
              className='contact-font'
            >
              <MenuItem disabled value="">
                I am interested in
              </MenuItem>
              {service_items.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <div className='contactus_form_inside'>
            {/* <Typography variant="h6">Selected Items:</Typography> */}
            {selectedOptions.map((item, index) => (
              <Typography key={index} variant="body1">
                {item}
              </Typography>
            ))}
          </div>
            <input
              type="tel"
              id="phone"
              name="phoneNumber"
              placeholder="Contact Number"
              pattern="[0-9]{5}[0-9]{5}"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              size='10'
              className='contact-font'
              style={{ width: '400px', height: '27px', fontSize: '15px' , marginTop: '10px'}}
              
            />
            <br />
            <input
              type="email"
              id='email'
              name='email'
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
              required
              className='contact-font'
              style={{ width: '400px', height: '27px', fontSize: '15px' , marginTop: '10px'}}
            />
            <br />
            <Button variant="contained" type="submit" onClick={handleClick} style={{ marginBottom: '25px' , marginTop: '10px', backgroundColor:'red'}}> 
              Submit
            </Button>
          </form>
        </Grid>
        {formSubmitted &&(
          <Grid item>
            <div style={{color:'white', marginBottom: '20px' }}>
              <Typography variant="h6">Thanks!</Typography>
              <Typography variant="body1">Our executive will call you shortly</Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default ContactUs2;

import React, { useState } from 'react'
import { ServiceItems } from "../Navbar/ServiceItems";
import './ServiceDetailed2.css'
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { createTheme } from '@mui/material';


const Img = styled('img')({
    margin: 'auto',
    // display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '8rem',

  });

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1400,
        xl: 1536,
      },
    },
});
  

export default function ServicesInDetailed2() {

    const handleMenuItemClick = (item) => {
        // Check if the item has a local reference (id)
        if (item) {
          // Get the corresponding element by id
          const targetElement = document.getElementById('Contact-Us');
    
          // Scroll to the target element
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      };
  return (
    <div className='service-container'>
        {/* <div className='service-block1'> */} 
            {ServiceItems.map((item,index)=>{
                return( 
                    <Container className='test' key={index} sx={{padding:'0'}} >
                        {/* <img className='service-image' src={item.image} alt=""/> */}
                        {/* <Paper elevation={2}> */}
                        <Grid container className='test2' spacing={0} direction="column"  sx={{display: { xs: 'flex', md: 'none' }}} >
                            <Grid item >
                            <img className='service-image' id={item.title} src={item.image} alt=""/>
                            </Grid>
                            <Grid item sx={{width:'85%', margin: 'auto', marginTop: '10px'}}>
                            <h2 className='service-header' >{item.title}</h2>
                            </Grid>
                            <Grid item container spacing={0} direction="column"  sx={{width:'85%', margin: 'auto', padding: '0 0 4% 0'}}> 
                            {/* <Grid item>
                            <p > <h2 className='service-header' id={item.title}>{item.title}</h2></p>
                            <p>Office Spaces</p>
                            </Grid> */}
                            <Grid item>
                            <p ><div dangerouslySetInnerHTML={{__html:item.text}}></div></p>
                            </Grid>
                            <Grid item>
                            <p ><b>{item.text2}</b></p>
                            </Grid>
                            <Grid item>
                            <Button variant="contained" type="submit" onClick={handleMenuItemClick} style={{ marginBottom: '13px' , marginTop: '13px', backgroundColor:'red'}}> 
                                        Contact us
                                        </Button>
                            </Grid>
                            {/* <Grid item>
                                <hr class="new1"></hr>

                            </Grid> */}
                            </Grid>
                        </Grid> 

                        <Grid container direction="row" spacing={3} sx={{width:'90%', margin: 'auto', display: { xs: 'none',  lg: 'flex'} }} wrap= 'nowrap'> 
                            <Grid item sx={{ width: 300, height: 350,  display: { xs: 'none',  lg: 'flex'}}} >
                            <Img src={item.image} alt="">
                                </Img>
                            </Grid>
                            <Grid item container direction="row" sx={{display: { xs: 'none',  lg: 'block'}}}   >
                                <Grid item >
                                    <h4 className='service-header' id={item.title}>{item.title}</h4>
                                </Grid>
                                <Grid item>
                                    <p ><div dangerouslySetInnerHTML={{__html:item.text}}></div></p>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                    <p ><b>{item.text2}</b></p>
                                    </Grid>
                                    <Grid item>
                                        {/* <Button onClick={() => handleMenuItemClick('Contact-Us')} >Contact us</Button> */}
                                        <Button variant="contained" type="submit" onClick={handleMenuItemClick('Contact-Us')} style={{ marginBottom: '40px' , marginTop: '10px', backgroundColor:'red'}}> 
                                        Contact us
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>   
                        {/* </Paper>              */}
                    </Container >
                )})
            }
        {/* </div> */}
    </div>
  )
}

import { ServiceItems } from '../Navbar/ServiceItems'
import React from 'react'
import './CardServices.css'

function CardServices() {
  return (
    <div> 
        <section>
            <div className='container-card' id='services'>
                <h1 className='services_card'>Our Services</h1>
                <div className='cards'>
                    {ServiceItems.map((item, index) => {
                        return (
                            <div className= "card" key={index}>
                                
                                <a href={item.url} className= "card">
                                <h4 className='card_text'>{item.title}</h4>
                                <img className='card_image_services' src={item.image} alt={item.title} />
                                </a>
                            </div>

                            
                        )
                    })}
                </div>
            </div>
        </section>
    </div>
  )
}

export default CardServices
import './App.css';
import Carousel from './components/Carousel/Carousel';
import Navbar from './components/Navbar/Navbar';
import {carousel_images} from './components/Carousel/Data'
import CardServices from './components/CardServices/CardServices';
import ServicesInDetailed from './components/CardServices/ServicesInDetailed';
import AboutUsCarousel from './components/Carousel/AboutUsCarousel';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import CardServices2 from './components/CardServices/CardServices2';
import ServicesInDetailed2 from './components/CardServices/ServiceInDetailed2';
import NavbarM from './components/Navbar/NavbarM';
import ContactUs2 from './components/ContactUs/ContactUs2';
import DropdownMenu from './components/ContactUs/DropdownMenu';
import Footer2 from './components/Footer/Footer2';
import AboutUs from './components/Carousel/AboutUs';

function App() {
  return (
    <div className="App">
    <Navbar />
    {/* <NavbarM></NavbarM> */}
    <Carousel images={carousel_images}/>
    <CardServices />


    {/* <CardServices2 /> */}
    {/* <ServicesInDetailed /> */}
    <ServicesInDetailed2 />
    {/* <hr></hr> */}
    <AboutUs />
    {/* <AboutUsCarousel /> */}
    {/* <hr></hr> */}
    <ContactUs2 />
    {/* <ContactUs /> */}
    {/* <hr></hr> */}
    {/* <Footer /> */}
    {/* <hr></hr> */}

    <Footer2 />
    
    
    </div>
  );
}

export default App;

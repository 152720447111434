export const ServiceItems = [
    {
        title: 'Office Spaces',
        url: '#Office Spaces',
        cName: 'services',
        text: '<ul><li>Network of over 250 properties</li><li>Every requirement is unique, and so is the list of recommended properties that we share with you.</li><li>Our options vary from simple bare shell properties to a warm shell, Plug & Play, and Coworking spaces, all based on what suits you. You can also choose among property types like Grade A spaces, Grade B spaces, Upcoming buildings, Business centres, Stand-alone buildings etc.</li></ul>',
        text2: 'Schedule your free site visit now!' ,
        image: "/images/Office Space.jpg"
    },
    {
        title: 'Luxury Homes',
        url: '#Luxury Homes',
        cName: 'services',
        text: '<table><thead><th>For Buyers</th><th>For Sellers</th></thead><tbody><tr><td><li><b>Best coverage:</b> We won’t stop till we dig out the last property that suits your requirement</li></td><td><li><b>Verified buyers:</b> We won\'t let anyone without the right intent or capacity step at your door</li></td></tr><tr><td><li><b>Privacy Protected:</b>Your details are kept as secret till you find the right match</li></td><td><li><b>Privacy Protected:</b> Your details are kept a secret till you find the right match</li></td></tr><tr><td><li><b>With you all the way:</b> Right from shortlisting the property to executing the sale deed</li></td><td><li><b>With you all the way:</b>Right from listing the property to executing the sale deed</li></td></tr></tbody></table>',
        text2:'Interested? Contact us for further details',
        image: "/images/Luxury Home.jpg"
    },
    {
        title: 'Land Services',
        url: '#Land Services',
        cName: 'services',
        text: '<p><b>Acquire Land</b></p><ul><li>Lands available for Plots, Villas, Apartments, and Commercial spaces</li><li>Choose from options of Outright Purchase or Joint Development</li><li>Rigorous Due Diligence: All our land parcels are selected after a careful due diligence process which covers a wide range of issues like legal, approval-related, market feasibility etc.</li></ul><p><b>Sell Land</b></p><ul><li><b>Right Network:</b> Direct network of over 200 buyers consisting of Top Builders, HNIs and Industries</li><li><b>Right Reach:</b> We showcase your land only to relevant buyers</li><li><b>Right Price:</b> We help you discover the true potential of your land better than anyone in the market</li></ul><p><b>Land Reports</b></p><p>Make the right decision with the power of information. Our customized reports for land cover various aspects like legal, technical, approval, product feasibility, demand and local information. We provide the report within 3-5 days.</p>',
        text2:'Interested? Contact us for further details',
        image: "/images/Land.jpg"
    },
    {
        title: 'Investment',
        url: '#Investment',
        cName: 'services',
        // text: '<p><b>Acquire Land</b></p><ul><li>Lands available for Plots, Villas, Apartments, and Commercial spaces</li><li>Choose from options of Outright Purchase or Joint Development</li><li>Rigorous Due Diligence: All our land parcels are selected after a careful due diligence process which covers a wide range of issues like legal, approval-related, market feasibility etc.</li></ul><p><b>Sell Land</b></p><ul><li><b>Right Network:</b> Direct network of over 200 buyers consisting of Top Builders, HNIs and Industries</li><li><b>Right Reach:</b> We showcase your land only to relevant buyers</li><li><b>Right Price:</b> We help you discover the true potential of your land better than anyone in the market</li></ul><p><b>Land Reports</b></p><p>Make the right decision with the power of information. Our customized reports for land cover various aspects like legal, technical, approval, product feasibility, demand and local information. We provide the report within 3-5 days.</p>',
        text: '<p><b>Want to invest in real estate? You are in the right place</b></p><p>We offer a wide variety of investment opportunities like</p><ul><li>Land</li><li>Equity investment in top projects</li><li>Debt to top developers secured by 2 times or higher collateral</li><li>Pre-leased commercial assets</li><li>Reputed national and international real estate funds</li></ul>',
        text2:'Interested? Contact us for further details',
        image: "/images/Investment.jpg"
    },
    {
        title: 'Funding',
        url: '#Funding',
        cName: 'services',
        text: '<p><b>We are a team of ex-bankers and IIM graduates with combined experience of over 50 years and a proven track record of arranging funding for top developers in the city</b></p><p>With a Network of PSU, Private Banks, NBFCs, AIFs, and Private Funders, we deal in a variety of real estate funding instruments like:</p><ul><li>Land Funding</li><li>Construction Finance</li><li>Inventory Funding</li><li>LRD</li><li>Take outs</li><li>Structure Finance</li></ul>',
        text2:'Interested? Contact us for further details',
        image: "/images/Funding.jpg"
    },
    {
        title: 'Home Loans',
        url: '#Home Loans',
        cName: 'services',
        text: '<p>Our wider reach helps you get the best interest rate and eligible amount. Our lender list covers reputed institutes like State Bank of India, Punjab National Bank, Bank of Maharashtra, ICICI Bank, HDFC Bank, Axis Bank, Bajaj Finance, Tata Capital, Adarsh Cooperative Bank and more</p><p><b>New Home Loan</b></p><ul><li>Looking to purchase a new home? Contact us to get the best eligible amount and interest rate</li></ul><p><b>Top up Loan</b></p><ul><li>Get an additional amount on existing loan</li></ul><p><b>Balance Transfer</b></p><ul><li>Get lower interest rate by transferring your loan to our partner banks</li></ul>',
        text2:'Interested? Contact us for further details',
        image: "/images/Home Loan.jpg"
    }
]